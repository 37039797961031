import { useCurrentUserQuery } from '../generated/graphql';
import React, { useState } from 'react';

import gql from 'graphql-tag';
import { createContext, useEffect } from 'react';
import { captureException } from '@sentry/nextjs';
import { useAuthContext } from './useAuthContext';

gql`
  query currentUser($firebaseId: String!) {
    user: userPersonnalData(where: { firebaseId: { _eq: $firebaseId } }) {
      createdAt
      displayName
      email
      firebaseId
      pseudo
      photoUrl
      updatedAt
      id
      user {
        id
        profiles {
          ...ProfileInfoFragment
        }
      }
    }
  }

  fragment ProfileInfoFragment on profile {
    cupId
    id
    userId
    points {
      cupId
      profileId
      rank
      totalPoints
    }
  }
`;

function useCurrentUserValue() {
  const { firebaseUser, isUserSignIn, isAuthReady } = useAuthContext();
  const [errorCount, setErrorCount] = useState(0);

  const [{ data, fetching, error }, fetchUser] = useCurrentUserQuery({
    variables: {
      firebaseId: firebaseUser?.uid ?? 'anonymous',
    },
    pause: !isUserSignIn,
  });

  useEffect(() => {
    if (!error) {
      setErrorCount(0);
      return;
    }
    if (!error.name.includes('Missing Authorization header in JWT authentication mode')) {
      return;
    }
    captureException(error);
    if (errorCount >= 4) {
      console.log('Too many errors');
    } else {
      setErrorCount((it) => it + 1);
      console.log('refetching user', { error, firebaseUser });
      fetchUser({ requestPolicy: 'network-only' });
    }
  }, [fetchUser, error, errorCount, firebaseUser]);

  useEffect(() => {
    if (!fetching && isUserSignIn) {
      const id = setTimeout(() => fetchUser({ requestPolicy: 'network-only' }), 30000);
      return () => clearTimeout(id);
    }
  }, [fetching, fetchUser, isUserSignIn]);

  const user = data?.user?.[0];
  const profile = user?.user?.profiles?.[0];

  const isLoading = isAuthReady || (fetching && !data);

  return {
    error,
    user,
    loading: isLoading,
    profile,
    forceRefresh: () => fetchUser({ requestPolicy: 'network-only' }),
  };
}

export type UseCurrentUserContextContextType = ReturnType<typeof useCurrentUserValue>;

const UseCurrentUserContext = createContext<UseCurrentUserContextContextType | undefined>(undefined);

export const CurrentUserProvider = ({ children }: { children: React.ReactNode }) => {
  const value = useCurrentUserValue();

  return <UseCurrentUserContext.Provider value={value}>{children}</UseCurrentUserContext.Provider>;
};

export const useCurrentUser = () => {
  const context = React.useContext(UseCurrentUserContext);
  if (context === undefined) {
    throw new Error('useCount must be used within a CurrentUserProvider');
  }
  return context;
};
